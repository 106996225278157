
.profile-bar {
    display: flex;
    flex-direction: row;

    height: min-content;

    gap: 10px;

    padding: 15px;

    align-items: center;

    color: #fff;
}

.profile-bar-server {
    display: flex;
    
    margin-right: auto;
}

.profile-bar-status {
    display: flex;

    gap: 10px;

    margin-left: auto;
}

.profile-bar-status span {
    margin: auto 0;
}

.profile-bar-status img {
    width: 50px;
    height: 50px;

    border-radius: 99999px;
}

.profile-block {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 80vh;

    gap: 20px;
}

.profile-data {
    display: flex;
    flex-direction: column;

    width: 40%;
    height: auto;

    padding: 10px 10px;
    gap: 10px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.profile-avatar {
    display: flex;
    flex-direction: row;
}

.profile-avatar img{
    width: 90px;
    height: 90px;

    margin: 15px;

    border: 3px blue solid;
    border-radius: 99999px;
}

.profile-text {
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.profile-nick {
    margin-top: auto;

    font-size: 24px;
}

.profile-status {
    margin-bottom: auto;

    font-size: 12px;
}

.profile-last-login {
    display: flex;
    flex-direction: column;

    padding: 0;
    gap: 10px;
}

.profile-buttons {
    display: flex;
    flex-direction: column;

    padding: 0;
    gap: 10px;

    text-align: center;
}

.profile-buttons a {

}

.profile-stats {
    display: flex;
    flex-direction: column;

    height: min-content;

    margin-top: 20px;
    padding: 0 0;
    gap: 20px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 12px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.profile-stats .profile-stats-ids {
    padding: 10px;
}

.profile-stats .profile-stats-time {
    padding: 10px;
}

.profile-stats .profile-stats-bans {
    padding: 10px;
}

.stat-column {
    display: flex;
    flex-direction: row;

    padding: 3px 0;
    
}

.stat-column .stat-name {
    margin-right: auto;
}

.stat-column .stat-value {
    margin-left: auto;
    text-align: right;
}

.profile-logs {
    display: flex;
    flex-direction: column;

    width: 60%;
    height: auto;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.profile-logs .chat-utils {
    display: flex;
    flex-direction: row;

    gap: 10px;

    padding: 15px 10px;
    
    align-items: center;
}

.profile-modal-warn {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    background: #00000075;

    align-items: center;
    justify-content: center;
}

.profile-modal-warn .warn-details {
    display: flex;
    flex-direction: column;

    width: 400px;
    height: min-content;

    padding: 10px;
    gap: 20px;

    background: #192240;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 12px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}



#scrll-prfl {
    


    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

#scrll-prfl::-webkit-scrollbar-thumb:hover {
    scrollbar-color: #5749d2;
}

#scrll-prfl::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

#scrll-prfl::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

#scrll-prfl .chat-utils {
    display: flex;
    flex-direction: row;

    gap: 10px;

    padding: 15px 0;
    
    align-items: center;
}

#scrll-prfl .chat-message {
    display: flex;

    gap: 10px;

    width: 100%;
    height: min-content;

    margin-top: 20px;
}

#scrll-prfl .chat-avatar {
    display: flex;
    flex-direction: column;

    width: min-content;
    height: min-content;
}

#scrll-prfl .chat-avatar img {
    position: relative;

    width: 48px;
    height: 48px;

    border-radius: 99999px;
}

#scrll-prfl .chat-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: min-content;
}

#scrll-prfl .chat-nick {
    display: flex;

    width: 100%;
    height: min-content;
}

#scrll-prfl .chat-nick span {
    font-size: 12px;
}

#scrll-prfl .chat-text {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
}

#scrll-prfl .chat-text p {

    width: 100%;
    height: auto;

    word-break: break-word;

    font-size: 12px;
}

.ip-coincidence {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.ip-coincidence::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.ip-coincidence::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.ip-coincidence::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.ip-coincidence th {
    padding: 10px 0;
}

.ip-coincidence td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.ip-coincidence tr {
    border: 5px #fff solid;
}

.ip-coincidence a {
    color: #fff;
}

.ban-logs {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.ban-logs::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.ban-logs::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.ban-logs::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.ban-logs th {
    padding: 10px 0;
}

.ban-logs td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.ban-logs tr {
    border: 5px #fff solid;
}

.ban-logs a {
    color: #fff;
}

.mute-logs {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.mute-logs::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.mute-logs::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.mute-logs::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.mute-logs th {
    padding: 10px 0;
}

.mute-logs td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.mute-logs tr {
    border: 5px #fff solid;
}

.mute-logs a {
    color: #fff;
}

.getwarn-logs {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.getwarn-logs::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.getwarn-logs::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.getwarn-logs::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.getwarn-logs th {
    padding: 10px 0;
}

.getwarn-logs td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.getwarn-logs tr {
    border: 5px #fff solid;
}

.getwarn-logs a {
    color: #fff;
}

.warn-logs {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.warn-logs .warn-column {
    display: flex;

    gap: 10px;

    height: min-content;

    margin-top: 20px;

    align-items: center;
}

.warn-logs::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.warn-logs::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.warn-logs::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.warn-logs a {
    color: #fff;
}

.warn-logs th {
    padding: 10px 0;
}

.warn-logs td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.warn-logs tr {
    border: 5px #fff solid;
}

.issued-ban-logs {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.issued-ban-logs::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.issued-ban-logs::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.issued-ban-logs::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.issued-ban-logs th {
    padding: 10px 0;
}

.issued-ban-logs td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.issued-ban-logs tr {
    border: 5px #fff solid;
}

.issued-ban-logs a {
    color: #fff;
}

.issued-mute-logs {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.issued-mute-logs::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.issued-mute-logs::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.issued-mute-logs::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.issued-mute-logs th {
    padding: 10px 0;
}

.issued-mute-logs td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.issued-mute-logs tr {
    border: 5px #fff solid;
}

.issued-mute-logs a {
    color: #fff;
}

.issued-warnings-logs {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.issued-warnings-logs::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.issued-warnings-logs::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.issued-warnings-logs::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.issued-warnings-logs th {
    padding: 10px 0;
}

.issued-warnings-logs td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.issued-warnings-logs tr {
    border: 5px #fff solid;
}

.issued-warnings-logs a {
    color: #fff;
}

.ip-history {
    display: flex;
    flex-direction: column;

    padding: 0 20px;
    gap: 10px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.ip-history::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.ip-history::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.ip-history::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.ip-history th {
    padding: 10px 0;
}

.ip-history td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.ip-history tr {
    border: 5px #fff solid;
}

.ip-history a {
    color: #fff;
}
