
.log-block {
    display: flex;
    flex-direction: column;

    width: 25%;
    height: min-content;

    padding: 20px;
    gap: 20px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.log-block > span {
    margin-bottom: 16px;
}

.text-log-block {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 800px;

    padding: 0 20px;
    gap: 20px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    overflow: hidden;
    overflow-y: scroll;

    word-break: break-word;
}

.text-log-block::-webkit-scrollbar-thumb:hover {
    scrollbar-color: #5749d2;
}

.text-log-block::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.text-log-block::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.visit-log-block {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 800px;

    padding: 0 20px;
    gap: 20px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;
}

.visit-log-block::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.visit-log-block::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.visit-log-block::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.visit-utils {
    display: flex;
    flex-direction: row;

    gap: 10px;

    padding: 15px 0;
    
    align-items: center;
}

.visit-utils #calendar {
    min-width: 170px;
    max-width: 170px;
    width: 170px;
    
    font-size: 14px;
}

.visit-log-block th {
    padding: 10px 0;
}

.visit-log-block td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.visit-log-block tr {
    border: 5px #fff solid;
}

.visit-log-block img {
    width: 32px;
}

.visit-log-block a {
    color: #fff;
}

.visit-modal-warn {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    background: #00000075;

    align-items: center;
    justify-content: center;
}

.visit-modal-warn .calendar-details {
    display: flex;
    flex-direction: column;

    
    height: min-content;

    padding: 10px;
    gap: 20px;

    background: #192240;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    color: #000;
}

.admin-log-block {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 800px;

    padding: 0 20px;
    gap: 20px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;

    word-break: break-word;
}

.admin-log-block::-webkit-scrollbar-thumb:hover {
    background-color: #5749d2;
}

.admin-log-block::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.admin-log-block::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.admin-utils {
    display: flex;
    flex-direction: row;

    gap: 10px;

    padding: 15px 0;
    
    align-items: center;
}

.admin-utils #calendar {
    font-size: 14px;
}

.admin-log-block th {
    padding: 10px 0;
}

.admin-log-block td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;

    overflow: hidden;
}

.admin-log-block tr {
    border: 5px #fff solid;
}

.admin-log-block img {
    width: 32px;
}

.admin-log-block a {
    color: #fff;
}

.admin-modal-warn {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    background: #00000075;

    align-items: center;
    justify-content: center;
}

.admin-modal-warn .calendar-details {
    display: flex;
    flex-direction: column;

    
    height: min-content;

    padding: 10px;
    gap: 20px;

    background: #192240;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    color: #000;
}

#scrll {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 80vh;

    padding: 0 20px;
    gap: 10px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 18px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

#scrll::-webkit-scrollbar-thumb:hover {
    scrollbar-color: #5749d2;
}

#scrll::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

#scrll::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

#scrll .chat-utils {
    display: flex;
    flex-direction: row;

    gap: 10px;

    padding: 15px 0;
    
    align-items: center;
}

#scrll .chat-utils #calendar {
    font-size: 14px;
}

#scrll .chat-message {
    display: flex;

    gap: 10px;

    width: 100%;
    height: min-content;

    margin-top: 20px;
}

#scrll .chat-avatar {
    display: flex;
    flex-direction: column;

    width: min-content;
    height: min-content;
}

#scrll .chat-avatar img {
    position: relative;

    width: 48px;
    height: 48px;

    border-radius: 99999px;
}

#scrll .chat-content {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: min-content;
}

#scrll .chat-nick {
    display: flex;

    width: 100%;
    height: min-content;
}

#scrll .chat-nick span {
    font-size: 14px;
}

#scrll .chat-text {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
}

#scrll .chat-text p {

    width: 100%;
    height: auto;

    word-break: break-word;

    font-size: 14px;
}

.chat-modal-warn {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    background: #00000075;

    align-items: center;
    justify-content: center;
}

.chat-modal-warn .calendar-details {
    display: flex;
    flex-direction: column;

    
    height: min-content;

    padding: 10px;
    gap: 20px;

    background: #192240;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 14px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    color: #000;
}

#calendar {
    min-width: 170px;
    max-width: 170px;
    width: 170px;
    
    font-size: 14px;
}

.users-log-block {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 80vh;

    padding: 0 20px;
    gap: 10px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.users-log-block::-webkit-scrollbar-thumb:hover {
    scrollbar-color: #5749d2;
}

.users-log-block::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.users-log-block::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.users-log-block .chat-utils {
    display: flex;
    flex-direction: row;

    gap: 10px;

    padding: 15px 0;
    
    align-items: center;
}

.users-log-block img {
    position: relative;

    width: 64px;
    height: 64px;

    border-radius: 99999px;
}

