
.custom-select {
    position: relative;

    justify-content: center;

    min-width: 400px;
    width: min-content;
    min-height: 30px;

    padding: 10px 25px;

    border: 2px #FFFFFF0D solid;
    border-radius: 12px;

    background: #FFFFFF1A;
    color: #fff;
    font-size: 12px;
}

.custom-select option {
	background: #FFFFFF1A;
    color: #000;
    font-size: 12px;

	border-radius: 10px;
}