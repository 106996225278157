
.not-found {
    display: flex;
    flex-direction: column;
}

.not-found > span {
    font-size: 48px;
    margin-bottom: 20px;
}

.not-found > a {
    font-size: 24px;
    text-decoration: underline;
}

.not-found > img {
    opacity: 0.03;
    user-select: none;
    pointer-events: none;
}