
.server-settings-block {
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 20px;
}

.server-list {
    display: flex;
    flex-direction: column;

    
    height: 70vh;

    padding: 0 20px;
    gap: 10px;

    background: #1E91E403;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 16px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    overflow: scroll;
    overflow-y: auto;
    overflow-x: hidden;
}

.server-list::-webkit-scrollbar-thumb:hover {
    scrollbar-color: #5749d2;
}

.server-list::-webkit-scrollbar {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #000;
    background-clip: content-box;
}

.server-list::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #8070d4;
    background-clip: content-box;
}

.server-utils {
    display: flex;
    flex-direction: row;

    gap: 10px;

    padding: 15px 0;
    
    align-items: center;
}

.server-list th {
    padding: 10px 0;
}

.server-list td {
    padding: 10px 0;
    border-top: 1px #ffffff7e solid;
}

.server-data-input {
    justify-content: center;

    min-width: 200px;
    min-height: 30px;

    padding: 15px 25px;

    border: 2px #FFFFFF0D solid;
    border-radius: 12px;

    background: #FFFFFF1A;
    color: #fff;
    font-size: 12px;
}

.server-list .server-data {
    display: flex;

    gap: 10px;

    height: min-content;

    margin-top: 20px;

    align-items: center;
}

.server-add-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    background: #00000075;

    align-items: center;
    justify-content: center;
}

.server-add-modal .server-details {
    display: flex;
    flex-direction: column;

    
    height: min-content;

    padding: 10px;
    gap: 20px;

    background: #192240;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 12px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    color: #000;
}

.server-delete-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;

    background: #00000075;

    align-items: center;
    justify-content: center;
}

.server-delete-modal .server-details {
    display: flex;
    flex-direction: column;

    
    height: min-content;

    padding: 10px;
    gap: 20px;

    background: #192240;
    border: 3px #162342 solid;
    border-radius: 10px;
    font-size: 12px;

    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    color: #fff;
}

.server-delete-modal .server-details .time-to-choose {
    display: flex;
}

.server-delete-modal .server-details .time-to-choose button {
    width: 58px;
    height: 52px;
}

.server-delete-modal .server-details .time-to-choose #yes {
    margin-right: auto;
}

.server-delete-modal .server-details .time-to-choose #no {
    margin-left: auto;
}
