@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Fugaz+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
@import url('https://fonts.cdnfonts.com/css/gotham-pro');
/*
font-family: 'Carter One', cursive;
font-family: 'Fugaz One', cursive;
*/

* {
	font-family: 'Gotham Pro', sans-serif;
}

html {
    width: 100%;
	height: 100%;
}

:root {
	--blue-gradient: #01193E;
	--red-gradient: #450812;
}

#root {
	width: 100%;
	height: 100%;
}

body {
	background: linear-gradient(135deg, var(--blue-gradient), var(--red-gradient));
	height: 100%;
	margin: 0;
}

a {
	color: #BABCC5;
	text-decoration: none;
}

.rippleEffect {
    position: absolute;
    background: white;
    pointer-events: none;
    border-radius: 99999px;
    transform: translate(-50%, -50%);
    animation: rippleanimation 0.5s linear infinite;
}

@keyframes rippleanimation {
    0% {
        width: 0px;
        height: 0px;
        opacity: 0.2;
    }
    100% {
        width: 1500px;
        height: 1500px;
        opacity: 0;
    }
}

.page {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    
    padding: 10px 20px;

    color: white;
    gap: 20px;
}

.text-align-center {
    text-align: center;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.pos-rel {
    position: relative;
}

.flex-body {
	display: flex;
	height: 100%;
}

.search-input {
    justify-content: center;

    min-width: 200px;
    min-height: 30px;

    padding: 15px 25px;

    border: 2px #FFFFFF0D solid;
    border-radius: 12px;

    background: #FFFFFF1A;
    color: #fff;
    font-size: 12px;
}

.search-input:focus {
    outline: none;
}

.green-button {
    color: white;
    font-size: 12px;
    padding: 10px 0;
    border: 0px;
    border-radius: 16px;
    background: #77AC46;
    overflow: hidden;
}

.red-button {
    color: white;
    font-size: 12px;
    padding: 10px 0;
    border: 0px;
    border-radius: 16px;
    background: #E32020;
    overflow: hidden;
}

.grey-button {
    color: white;
    font-size: 12px;

    width: auto;
    height: min-content;

    padding: 15px 10px;

    border: 0px;
    border-radius: 16px;
    background: #FFFFFF1A;
    overflow: hidden;
}

.green-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.red-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.grey-button:hover {
    opacity: 0.9;
    cursor: pointer;
}

.font-carter-one {
	font-family: 'Carter One', cursive;
}

.font-fugaz-one {
	font-family: 'Fugaz One', cursive;
}

.font-nunito {
	font-family: 'Nunito', sans-serif;
}

/* #scrollable-block::-webkit-scrollbar {
    display: none;
} */

.count-columns {
    font-size: 12px;
}

.wd-100 {
    width: 100%;
}

.fs-22 {
    font-size: 22px;
}
