:root {

	--header-color: #192240;
}

.flex-card-image {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.header {
	background: var(--header-color);
	display: flex;
	flex-direction: column;
	
	position: sticky;
	top: 0;

	width: 100px;

	padding: 10px 11px;
	gap: 30px;

	color: #BABCC5 !important;
	font-size: 14px;
}

.logo {
	width: 52px;
}

.nav-card {
	display: flex;
	align-items: center;
	justify-content: center;
}